import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import TextInput from 'components/TextInput';
import { CtaIcon } from 'components/_Redesign/CallToAction/styles';
import { CTA_ICON_LG_SIZE } from 'components/_Redesign/CallToAction/constants';

import { ButtonReset, SearchStyled } from './styles';
import Input from '../_Redesign/Input';

interface Props {
  name: string;
  placeholder: string;
  value?: string;
  type?: string;
  error?: boolean;
  disabled?: boolean;
  isDisabled?: boolean;
  isResetShow?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Search: FC<Props> = ({ onClear, isDisabled, isResetShow, ...props }) => {
  const t = usePrefix('General');

  return (
    <SearchStyled>
      <Input type="search" sizeField="md" {...props} autoFocus isAction />
      {isResetShow ? (
        <ButtonReset
          type="reset"
          onClick={onClear}
          title={t('cta_clear')}
          isDisabled={isDisabled}
          variant="text"
          color="text-primary"
          size="lg"
        >
          <CtaIcon type="close" width={CTA_ICON_LG_SIZE} height={CTA_ICON_LG_SIZE} />
        </ButtonReset>
      ) : null}
    </SearchStyled>
  );
};

export default memo(Search);
